// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 0;
}

.btn {
    display: inline-flex;
    border-radius: 28px;
}

.btn-sm,
.btn-group-sm > .btn {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
}

.btn-primary:hover {
    border-color: #72add4 !important;
}

.btn-secondary:hover {
    color: #f4f4f4;
}

.pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
}
.pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
}
.pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
}
.pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
}

.copyrightColumn {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.poweredByColumn {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.poweredBy {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    text-align: center;
    font-family: sans-serif;
    color: #212529;
}

.poweredBy div {
    display: flex;
    justify-content: center;
}

.poweredBy .companyName {
    display: flex;
    align-items: center;
}

.poweredBy a.companyName {
    color: #5533ff;
    text-decoration: none;
    background-color: transparent;
}

.poweredBy a.companyName:hover {
    color: #0056b3;
    text-decoration: underline;
}

.carousel-control-prev-icon.carousel-arrow,
.carousel-control-next-icon.carousel-arrow {
    background-image: none !important;
    height: 48px;
    width: 48px;
    border: 1px solid #fff;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

#about-us p {
    font-size: 0.975rem;
    font-family: "Futura-Book";
    color: #0b1b1a;
}
/* Messages */
#conversation-ui,
#welcome-to-chat {
    display: none;
}
#conversation-ui.active,
#welcome-to-chat.active {
    display: block;
}
#messageBody {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .messageBubble {
        padding: 0.5em;
        border-radius: 8px;
        margin: 0.5em;
        width: fit-content;
        max-width: 50%;
        box-shadow: 1px 2px 3px #ccc;
        p {
            margin: 0;
        }
        .senderName {
            font-weight: bolder;
        }
        img,
        video {
            width: 100%;
        }
    }
    .messageSended {
        background-color: aliceblue;
        align-self: flex-end;
        .senderName {
            text-align: right;
        }
    }
    .messageRecieved {
        background-color: antiquewhite;
    }
}
#message {
    min-height: 40px;
    &:focus {
        box-shadow: none;
    }
}
#attachment-preview {
    max-width: 100px;
    max-height: 80px;
    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 1;
    }
    button {
        z-index: 2;
    }
}
.unread-messages-indicator {
    display: none;
}
.unread-messages-indicator.active {
    background-color: red;
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin: 0 0.5rem;
    border-radius: 50%;
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    display: grid;
    place-items: center;
    box-sizing: border-box;
    text-align: center;
    transform: scale(0.8);
}

/*Social Media Sign in buttons*/

.google-btn {
    background-color: #4285f4;
}
.facebook-btn {
    background-color: #4267b2;
}
.social-btn,
.social-btn:hover {
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 0.5em auto;
    padding: 0.5em;
    text-align: left;
    color: white;
    font-weight: 300;
    text-decoration: none;
    gap: 1;
    flex: 1;
    .svg {
        height: 30px;
        object-fit: contain;
        flex: 0.5;
    }
}
header .nav-link.active,
header .nav-link:hover {
    color: #0b1b1a;
}
.contactInfoBarContainer,
.navigationBarContainer {
    padding-left: 15px;
    padding-right: 15px;
}
.font-10 {
    font-size: rfs-value(10px);
}
.font-20 {
    font-size: rfs-value(20px);
}
.font-30 {
    font-size: rfs-value(30px);
}
.font-40 {
    font-size: rfs-value(40px);
}
.font-45 {
    font-size: rfs-value(45px);
}
.carousel-caption {
    bottom: 0;
}
.carousel-arrow::before {
    content: "";
    display: block;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    padding: 4px;
    transform: rotate(-45deg);
}
.carousel-control-next-icon::before {
    transform: rotate(-45deg);
    margin-right: 3px;
}
.carousel-control-prev-icon::before {
    transform: rotate(135deg);
    margin-left: 3px;
}
.teamImageContainer {
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: -75px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.teamImageCaption {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 75px 35px 30px;
}

.close-filter {
    flex: none !important;
}
